import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';
import NewAnalysisOrder from '../pages/user/newanalysisorder/NewAnalysisOrder';
import MonthlyAnalysisCredits from '../pages/user/monthlyanalysiscredits/MonthlyAnalysisCredits';

const ShareRoute = () => {
    const { token, isAdmin, accountType } = useToken();
    // return accountType ? <Outlet /> : <Navigate to="/monthly-analysis-credits" />
    return accountType ? <Outlet /> : <Navigate to="/subscription-user" />
}

export default ShareRoute;