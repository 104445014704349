// Libraries
import React, { useEffect, useState } from "react";
import { Typography, Image, Row, Col, Spin, List, Button } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import PdfFileLists from "../../../components/pdffilelists/PdfFileLists";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import interceptor from "../../../shared/interceptor";
import useToken from "../../../hooks/useToken";

// Style
import "./index.css";

// Assets
import mobileImg from "../../../assets/images/view-results-screen.png";
import mobileAnalysis from "../../../assets/images/view-analysis-screen.png";
import webApp from "../../../assets/images/web-app-img.jpg";
import orderBg from "../../../assets/images/order-header.jpg";
import logo from "../../../assets/images/logo.png";
import canalytics from "../../../assets/images/canalytics.png";
import { Pdf } from "../../../icons/Icons";

// const html = `
// <table class="row row-4" style="margin-top:30px" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
// 						<tbody>
// 							<tr>
// 								<td>
// 									<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 680px; margin: 0 auto;" width="680">
// 										<tbody>
// 											<tr>
// 												<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
// 													<div class="spacer_block block-1" style="height:20px;line-height:20px;font-size:1px;">&hairsp;</div>
// 													<table class="paragraph_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
// 														<tbody><tr>
// 															<td class="pad">
// 																<div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:17px;line-height:150%;text-align:center;mso-line-height-alt:25.5px;">
// 																	<p style="margin: 0; word-break: break-word;"><strong>Orders for analysis are typically processed and delivered within 72 business hours. If you have submitted COAs from labs that we have not previously analyzed, please allow an additional 1-2 days for our system to undergo training in order to process the COAs.</strong></p>
// 																</div>
// 															</td>
// 														</tr>
// 													</tbody></table>
// 													<table class="paragraph_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
// 														<tbody><tr>
// 															<td class="pad">
// 																<div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:18px;line-height:150%;text-align:center;mso-line-height-alt:27px;">
// 																	<p style="margin: 0;"><strong>The Canalytics Web App can be viewed on any PC or Mac computer as well as any iOS or Android smartphone or tablet.</strong></p>
// 																</div>
// 															</td>
// 														</tr>
// 													</tbody></table>
// 													<div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&hairsp;</div>
// 												</td>
// 											</tr>
// 										</tbody>
// 									</table>
// 								</td>
// 							</tr>
// 						</tbody>
// 					</table>
// <table class="row row-5" style="margin-top:30px" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2886/section-2-bkg.png'); background-position: center top; background-repeat: no-repeat;">
// <tbody>
//   <tr>
//     <td>
//       <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 680px; margin: 0 auto;" width="680">
//         <tbody>
//           <tr>
//             <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; padding-left: 10px; padding-right: 10px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
//               <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
//                 <tbody><tr>
//                   <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
//                     <div class="alignment" align="center" style="line-height:10px"><img src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/1026135_1011104/WebApp-New-Analysis---View-Results-Screen-%28Mobile-iPhone%29.png" style="display: block; height: auto; border: 0; max-width: 240px; width: 100%;" width="240" alt="iPhone Light Intencity" title="iPhone Light Intencity"></div>
//                   </td>
//                 </tr>
//               </tbody></table>
//             </td>
//             <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 30px; padding-left: 10px; padding-right: 10px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
//               <table class="paragraph_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
//                 <tbody><tr>
//                   <td class="pad">
//                     <div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:26px;line-height:120%;text-align:left;mso-line-height-alt:31.2px;">
//                       <p style="margin: 0; word-break: break-word;"><strong>1) Open Results</strong></p>
//                     </div>
//                   </td>
//                 </tr>
//               </tbody></table>
//               <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
//                 <tbody><tr>
//                   <td class="pad" style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
//                     <div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:16px;line-height:150%;text-align:left;mso-line-height-alt:24px;">
//                       <p style="margin: 0; word-break: break-word;"><span>1) Upon successful processing and delivery of your analysis order, you will receive an email notification.<br><br></span></p>
//                       <p style="margin: 0; word-break: break-word;"><span>2) Once your order has been completed login to <strong><a href="https://insights.canalytics.ai" target="_blank" style="text-decoration: underline; color: #0431c4;" rel="noopener">https://insights.canalytics.ai</a></strong> and select <strong>View Results</strong>.<br></span></p>
//                       <p style="margin: 0; word-break: break-word;">&nbsp;</p>
//                       <p style="margin: 0; word-break: break-word;">3) Click the <strong>Open</strong> button next to the order you want to view.</p>
//                     </div>
//                   </td>
//                 </tr>
//               </tbody></table>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </td>
//   </tr>
// </tbody>
// </table>
// <table class="row row-7" style="margin-top:30px" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2886/section-3-bkg.png'); background-position: center top; background-repeat: no-repeat;">
// 						<tbody>
// 							<tr>
// 								<td>
// 									<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 680px; margin: 0 auto;" width="680">
// 										<tbody>
// 											<tr class="reverse">
// 												<td class="column column-1 first" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-left: 10px; padding-right: 10px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
// 													<div class="border">
// 														<div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&hairsp;</div>
// 														<table class="paragraph_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
// 															<tbody><tr>
// 																<td class="pad">
// 																	<div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:26px;line-height:120%;text-align:left;mso-line-height-alt:31.2px;">
// 																		<p style="margin: 0; word-break: break-word;"><strong>2) View Analysis</strong></p>
// 																	</div>
// 																</td>
// 															</tr>
// 														</tbody></table>
// 														<table class="paragraph_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
// 															<tbody><tr>
// 																<td class="pad" style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
// 																	<div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:16px;line-height:150%;text-align:left;mso-line-height-alt:24px;">
// 																		<p style="margin: 0;">1) Open an Analysis Report for an order.<br><br></p>
// 																		<p style="margin: 0;">2) Select cannabis effects to analyze while viewing all COA results simultaneously.<br><br></p>
// 																		<p style="margin: 0;">3) Explore a particular strain or phenotype for an in-depth analysis overview.<span></span></p>
// 																	</div>
// 																</td>
// 															</tr>
// 														</tbody></table>
// 													</div>
// 												</td>
// 												<td class="column column-2 last" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; padding-left: 10px; padding-right: 10px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
// 													<div class="border">
// 														<div class="spacer_block block-1" style="height:30px;line-height:30px;font-size:1px;">&hairsp;</div>
// 														<table class="image_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
// 															<tbody><tr>
// 																<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
// 																	<div class="alignment" align="center" style="line-height:10px"><img src="https://85a81e65ff.imgdist.com/public/users/Integrators/BeeProAgency/1026135_1011104/View-Results-Screen-%28Mobile-iPhone%29-2.png" style="display: block; height: auto; border: 0; max-width: 240px; width: 100%;" width="240" alt="iPhone Groups" title="iPhone Groups"></div>
// 																</td>
// 															</tr>
// 														</tbody></table>
// 													</div>
// 												</td>
// 											</tr>
// 										</tbody>
// 									</table>
// 								</td>
// 							</tr>
// 						</tbody>
// 					</table>
//           <table class="row row-9" style="margin-top:30px" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-position: top center;">
// 						<tbody>
// 							<tr>
// 								<td>
// 									<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 680px; margin: 0 auto;" width="680">
// 										<tbody>
// 											<tr>
// 												<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
// 													<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
// 														<tbody><tr>
// 															<td class="pad" style="width:100%;">
// 																<div class="alignment" align="center" style="line-height:10px"><img src="https://85a81e65ff.imgdist.com/public/users/Integrators/BeeProAgency/1026135_1011104/imac-1920x1080-%28Web-App-View-Results-USER%29-copy.jpg" style="display: block; height: auto; border: 0; max-width: 680px; width: 100%;" width="680"></div>
// 															</td>
// 														</tr>
// 													</tbody></table>
// 													<div class="spacer_block block-2" style="height:30px;line-height:30px;font-size:1px;">&hairsp;</div>
// 													<table class="paragraph_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
// 														<tbody><tr>
// 															<td class="pad">
// 																<div style="color:#18285c;font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:17px;line-height:150%;text-align:center;mso-line-height-alt:25.5px;">
// 																	<p style="margin: 0; word-break: break-word;"><strong>Canalytics' Web App utilizes data-driven algorithms, backed by 5,000+ studies, empower comprehensive cannabis COA effect analysis across 20 therapeutic and 10 recreational effects. Canalytics' services provide crucial actionable insights to brands, breeders, phenohunters, cultivators, and dispensaries.</strong></p>
// 																</div>
// 															</td>
// 														</tr>
// 													</tbody></table>
// 													<div class="spacer_block block-4" style="height:30px;line-height:30px;font-size:1px;">&hairsp;</div>
// 												</td>
// 											</tr>
// 										</tbody>
// 									</table>
// 								</td>
// 							</tr>
// 						</tbody>
// 					</table>
// `;

const { Title, Text, Link } = Typography;
function OrderSubmitted() {
  const naviagte = useNavigate();
  const { token, userID } = useToken();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [list, setList] = useState([]);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (id && list.length == 0) {
      getFileSttaus()
      interceptor
        .axiosPost("Post", "order-success/", { id }, token)
        .then((res) => {
          if (res.status === 200) {

          } else {
            console.log("error getting user : ", res);
          }
        })
        .catch((err) => {
          return err;
        });
    }
  });


  const getFileSttaus = () => {
    interceptor
      .axiosGet("Get", `order-files-status/?id=${id}`, token)
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.order.files);
          setStatus(res.data.order.status);
          if (res.data.order.status !== "COMPLETED") {
            setTimeout(() => {
              getFileSttaus()
            }, 3000)
          }
          else {
            setTimeout(() => {
              naviagte('/view-csv/' + id)
            }, 3000)

          }

        } else {
          console.log("error getting user : ", res);
        }
      })
      .catch((err) => {
        return err;
      });

  }
  return (
    <Layout title="Order Submitted">
      <div className="order-submit-screen">
        <div className="order-header">
          <div className="order-header-content">
            <div className="order-header-left">
              <Image preview={false} src={canalytics} alt="Canalytics" />
              <Title level={1}>New Order</Title>
            </div>
            <div className="order-header-right">
              <Image preview={false} src={logo} alt="Logo" />
            </div>
          </div>
          <div className="header-img">
            <Image preview={false} src={orderBg} alt="Order" />
          </div>
        </div>
        <Title style={{textAlign:'left'}} level={2}>{item?.company_name ? 'Your Analysis Order Has Been Placed' : 'Please wait while your order is being processed.'} </Title>
        <Row>
          <Col md={16}>
            <div className="pdf-list-data">
              <List
                bordered
              >
                <div className="list-header">
                  <Title level={5}>PDFs Processing Status</Title>
                </div>
                {
                  list && list.length > 0 && list.map((item, index) => (
                    <List.Item key={index} >
                      {index + 1}
                      <Pdf color="#1377C9" />
                      <List.Item.Meta style={{ display: 'flex', justifyContent: 'space-between' }}
                        title={<Link to="#">{item.name}</Link>}
                        description={

                          <Button
                            className="my-button"
                            style={{
                              backgroundColor: item.is_processed ? "#41b607" : "#E56080",
                              color: "#fff",
                              width: "initial",
                              display: "inline-block",
                              paddingTop: "6px",
                              paddingBottom: "6px",
                            }}
                          >
                            {item.is_processed ? 'Complete' : 'Processing'}
                          </Button>

                        }
                      />
                    </List.Item>
                  ))
                }
              </List >
            </div >
          </Col>
        </Row>

        {!item?.company_name ? < Spin className="loaderSpin"></Spin>
          : <Row justify="center">
            <Col md={16}>
              <div>

                <ul className="order-list">
                  <li>
                    <span>Order Name</span>: {item?.name}
                  </li>
                  <li>
                    <span>Order Date</span>:
                    {item?.created_at ? new Date(item?.created_at).toLocaleString() : ''}
                  </li>
                  <li>
                    <span>Order#: </span>
                    {item?.id}
                  </li>
                  <li>
                    <span>Company</span>: {item?.company_name}
                  </li>
                  <li style={{ marginTop: "30px" }}>
                    <span>Analysis </span>: {item?.order_details.package_name}
                  </li>
                  <li>
                    <span>COAs Analyzed</span>: {item?.files.length}
                  </li>
                  <li>
                    <span>Total</span>: ${item?.order_details.order_total}
                  </li>
                </ul>
              </div>

              <Title level={5}>
                Orders for analysis are typically processed and delivered within
                24-48 business hours. If you have submitted COAs from labs that we
                have not previously analyzed, please allow an additional 1-2 days
                for our system to undergo training in order to process the COAs.
              </Title>
              <Title level={5}>
                The Canalytics Web App can be viewed on any PC or Mac computer as
                well as any iOS or Android smartphone or tablet.
              </Title>
              <div className="canalytics-app-meta">
                <div className="canalytics-app-left">
                  <Image preview={false} src={mobileImg} alt="" />
                </div>
                <div className="canalytics-app-right">
                  <Title level={2}>1) Open Results</Title>
                  <div className="canalytics-app-content">
                    <Text>
                      1) Upon successful processing and delivery of your analysis
                      order, you will receive an email notification.
                    </Text>
                    <Text>
                      2) Once your order has been completed login to{" "}
                      <Link href="https://insights.canalytics.ai" title="">
                        https://insights.canalytics.ai
                      </Link>{" "}
                      and select <strong>View Results</strong>.
                    </Text>
                    <Text>
                      3) Click the <strong>Open</strong> button next to the order
                      you want to view.
                    </Text>
                  </div>
                </div>
              </div>
              <div className="canalytics-app-meta">
                <div className="canalytics-app-right canalytics-analysis-app-left">
                  <Title level={2}>2) View Analysis</Title>
                  <div className="canalytics-app-content">
                    <Text>1) Open an Analysis Report for an order.</Text>
                    <Text>
                      2) Select cannabis effects to analyze while viewing all COA
                      results simultaneously.
                    </Text>
                    <Text>
                      3) Explore a particular strain or phenotype for an in-depth
                      analysis overview.
                    </Text>
                  </div>
                </div>
                <div className="canalytics-app-left">
                  <Image preview={false} src={mobileAnalysis} alt="" />
                </div>
              </div>
              <div className="canatytics-web-app">
                <Image preview={false} src={webApp} alt="" />
                <Text>
                  Canalytics' Web App utilizes data-driven algorithms, backed by
                  5,000+ studies, empower comprehensive cannabis COA effect
                  analysis across 20 therapeutic and 10 recreational effects.
                  Canalytics' services provide crucial actionable insights to
                  brands, breeders, phenohunters, cultivators, and dispensaries.
                </Text>
              </div>
              {/* <p dangerouslySetInnerHTML={{ __html: html }}></p> */}
            </Col>
          </Row>
        }
        {/* <Row gutter={16}>
          <Col className="gutter-row" xl={10} lg={12} md={24} sm={24} xs={24}>
            <div className="start-order-list">
              <List
                bordered
                footer={
                  <Text>
                    Upon starting an order you will receive a digital invoice
                    via e-mail that must be paid before the order is processed.
                  </Text>
                }
              >
                <List.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>{item?.order_details.package_name == 'MEDICAL AND RECREATIONAL ADVANCED' ? 'Therapeutic AND Adult Use ADVANCED' : item?.order_details.package_name}</Text>
                    <Text>COAs Analyzed: {item?.files.length} </Text>
                  </div>
                  <Typography>$ {item?.order_details?.total_original_amount}</Typography>
                </List.Item>
                <List.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Volume Discount: {item?.order_details?.discount_percentage}%</Text>
                  </div>
                  <Typography>- ${item?.order_details?.discount_price}</Typography>
                </List.Item>
                <List.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Order Total: </Text>
                  </div>
                  <Typography>${item?.order_details?.order_total}</Typography>
                </List.Item>
              </List>
            </div>
          </Col>
          <Col className="gutter-row" xl={10} lg={12} md={24} sm={24} xs={24}>
            <PdfFileLists
              showButton={false}
              showtag={false}
              items={item}
              text="View All (7)"
              showFooter={false}
            />
          </Col>
        </Row> */}
      </div>
    </Layout>
  );
}

export default OrderSubmitted;
