// Libraries
import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Select } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import Upload from "../../../components/upload/Upload";
import MyButton from "../../../components/mybutton/MyButton";
import DonutChart from "../../../components/donutchart/DonutChart";
import ColumnChart from "../../../components/columnchart/ColumnChart";
import ChartTable from "../../../components/charttable/ChartTable";
import PdfFileLists from "../../../components/pdffilelists/PdfFileLists";

// Styles
import "./index.css";
import StackedChart from "../../../components/stackedchart/StackedChart";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../shared/interceptor";
import { CustomerType, packageValue } from "../../../data/admin/DDL";




const { Title } = Typography;
function DeliverOrder() {
  const userList = useSelector((state) => state.userReducer.userList)
  const { id } = useParams()
  const [orders, setOrders] = useState(null)
  const [visual, setVisual] = useState(null)
  const [subVisual, setSubVisual] = useState(null)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()
  const { token, isAdmin } = useToken()
  const naviagte = useNavigate();

  const approveDoucment = () => {
    setLoading(true)
    let model = {
      status: "COMPLETED",
      id: id
    }
    interceptor.axiosPost('Post', 'admin/change-status-of-order/', model, token).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        naviagte("/main-page");

      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  useEffect(() => {
    if (id && !orders) {
      getDetail()
    }

    if (userList?.length === 0) {
      dispatch(userActions.getUser(token))
    }
  })

  const uploadedFile = (value) => {
    getDetail()
  }

  const getDetail = () => {
    userActions.getUserOrders(token, id).then(async res => {
      await setOrders(res)
      userActions.getVisualOrderDetail(token, id).then(async res1 => {
        console.log(res1)
        await setVisual(res1)
        userActions.getSubVisualDetail(token, id, 1).then(async res2=> {
          await setSubVisual(res2)
        })
      })
    })
  }

  const Onchange = (value) => {
    console.log(value)
    setLoading(true)
    userActions.getSubVisualDetail(token, id, value).then(async res => {
      await setSubVisual(res)
      setLoading(false)

    })
  }

  const changeUser = (value) => {
    console.log(value)
  }

  return (
    <Layout title="Deliver Order: Download PDFs & Deliver CSV">
      <div className="deliver-order">
        {isAdmin &&
          <>
            {id ?
              <>
                <Title level={3}>Company Name : {orders?.company_name}</Title>
                <Title level={3} style={{ marginTop: 0 }}>Analysis Order #{orders?.id} : {orders?.name}  , (Date & Time) :   {orders?.updated_at ? new Date(orders?.updated_at).toLocaleString() : ''} </Title>
              </>
              :
              < div style={{ display: 'flex' }}>
                <div >
                  <Title level={3}>Select User</Title>
                  <Select
                    placeholder="Type"
                    showArrow
                    allowClear
                    style={{ width: '300px', marginBottom: '15px', paddingRight: '5px' }}
                  >
                    {userList?.users?.map((item) => (
                      <>
                        {
                          item.name !== 'Others' && item.name !== 'All' &&
                          <Select.Option key={item.id} value={item.id}

                            change={() => changeUser(item.id)}>
                            {item.email}
                          </Select.Option>
                        }
                      </>
                    ))}
                  </Select>
                </div>
                <div>
                  <Title level={3}>Select Package</Title>
                  <Select
                    placeholder="Package"
                    showArrow
                    allowClear
                    style={{ width: '300px', marginBottom: '15px' }}
                  >
                    {packageValue.map((item) => (
                      <>
                        {
                          item.name !== 'Others' && item.name !== 'All' &&
                          <Select.Option key={item.key} value={item.name}>
                            {item.name}
                          </Select.Option>
                        }
                      </>
                    ))}
                  </Select>
                </div>


              </div>
            }
            <Row gutter={16} align="top">
              {id &&
                <Col className="gutter-row" xl={12} lg={24} md={24} sm={24}>
                  <PdfFileLists showButton={false} showTag={true} items={orders} />

                </Col>
              }
              <Col className="gutter-row" xl={12} lg={24} md={24} sm={24}>
                {orders?.status != 'COMPLETED' &&
                  <div className="upload-card">
                    <Title level={4}>Admin: Upload CSV to Preview & Deliver Order</Title>
                    <div className="upload-file">
                      <Upload uploadedFile={uploadedFile} accept={'.csv,.xlsx'} />
                    </div>
                  </div>}
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  {orders?.status != 'COMPLETED' && <Button
                    className="my-button"
                    style={{
                      backgroundColor: "#609BE5",
                      color: "#fff",
                      width: "initial",
                      display: "inline-block",
                    }}
                    htmlType="submit"
                    onClick={approveDoucment}
                    loading={loading}
                  >
                    Approve Preview & Deliver
                  </Button>
                  }
                </div>
              </Col>
            </Row>
          </>
        }

        {orders?.is_admin_uploaded_data &&
          <>
            <div className="area-chart">
              <StackedChart showTitle={true} item={visual} change={Onchange} title={orders?.name} />
            </div>
            <div className="donut-chart-meta stacked-chart stacked-chart-selection" style={{ marginTop: '0px' }} >
              <Title style={{ marginTop: '0px' }} level={5}>Selection: {subVisual?.strain_name}</Title>
              <ColumnChart item={subVisual} loading={loading} />
              <Row gutter={16} align="top" style={{ marginTop: 30 }}>
                <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
                  <DonutChart id={'chart1'} item={subVisual?.donout_graph} loading={loading} title={'Terpene %'} subTitle={'Terpene Volume'} />
                </Col>
                <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
                  <DonutChart id={'chart2'} item={subVisual?.donout_graph_2} loading={loading} title={'Cannabinoid %'} subTitle={'Cannabinoid Volume'} />
                </Col>
              </Row>
            </div>
            <ChartTable item={visual} />
            <div style={{ textAlign: "center" }}>
              {orders?.status != 'COMPLETED' && <Button
                className="my-button"
                style={{
                  backgroundColor: "#609BE5",
                  color: "#fff",
                  width: "initial",
                  display: "inline-block",
                }}
                htmlType="submit"
                onClick={approveDoucment}
                loading={loading}
              >
                Approve Preview & Deliver
              </Button>}
            </div>
          </>
        }
      </div>
    </Layout>
  );
}

export default DeliverOrder;
