// Libraries
import React, { useEffect, useState } from "react";
import { Typography, Input, Row, Col, List, Button, Alert, Card } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import Upload from "../../../components/upload/Upload";
import PdfFileLists from "../../../components/pdffilelists/PdfFileLists";
import MyButton from "../../../components/mybutton/MyButton";

// Style
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import interceptor from "../../../shared/interceptor";
import { Link, useNavigate, useParams } from "react-router-dom";

const { Title, Text } = Typography;
function StartOrder() {

  const [packages, setPackage] = useState(null)
  const [discount, setDiscount] = useState(null)
  const dispatch = useDispatch()
  const { id } = useParams()
  const { token, userID } = useToken()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const naviagte = useNavigate();
  const [count, setCount] = useState(0)
  const [message, setMessage] = useState(null)
  const [availcredit, setAvailCredit] = useState(100)
  const [errorFile, setErrorFile] = useState([])

  const uploadedFile = (value, status) => {
    if (status) {
      setItems(oldArray => [...oldArray, value]);
      setCount(count + 1)
    }
    else {
      setErrorFile(oldfile => [...oldfile, value]);
    }

  }

  const removeItem = (value) => {
    let val = items.filter(x => x.id != value)
    setItems(val);
    getPackageDetail(val.length)
  }

  useEffect(() => {
    if (!discount) {
      getPackageDetail(count)
    }
  })

  const getPackageDetail = (total) => {
    // interceptor.axiosGet('GET', `get-order-details-based-on-selected-package/?selected_package=${parseInt(id) > 5 ? parseInt(id) : parseInt(id) - 1}&total_pdfs=${total}`, token).then((res) => {
    interceptor.axiosGet('GET', `get-order-details-based-on-selected-package/?selected_package=${8}&total_pdfs=${total}`, token).then((res) => {
      if (res.status === 200) {
        setPackage(res.data)
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  useEffect(() => {
    console.log(items)
    if (items.length > 0) { getPackageDetail(items.length) }
    if (!packages) {
      getPackage()
    }
  }, [items])

  const getPackage = () => {
    interceptor.axiosGet('GET', 'get-price-details-for-user/', token).then((res) => {
      if (res.status === 200) {
        setDiscount(res.data.discount)
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  const userOrder = () => {

    if (items.length > 0 && title) {
      setLoading(true)
      setMessage(false)
      let model = {
        "files": items.map(x => x.id),
        "name": title,
        // "package_selected": parseInt(id) > 5 ? parseInt(id) : parseInt(id) - 1
        "package_selected": 8
      }
      interceptor.axiosPost('Post', 'order-to-pay/', model, token).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          let CLIENT_SECRET = res.data.payment_intent.client_secret;
          naviagte(`/check-out/${CLIENT_SECRET}/${res.data.data.id}/null/null/null`);

        }
        else {
          console.log("error getting user : ", res)
        }
      }).catch((err) => {
        return err
      })
    }
    else {
      setMessage(!title ? 'Please Select Order Title' : 'Please upload file')
    }
  }
  return (
    <Layout title="New Analysis Order">
      <div className="start-orders">
        <Row gutter={16}>
          <Col xl={12} lg={12} md={12} sm={16} xs={12}>
            <div className="purchase-cradit">
              <div>
                <Link
                  className="my-link btnClass"
                  to="/view-orders"

                >
                  View Order Results
                </Link>
                <Link
                  className="my-link btnClass"
                  to="/subscription-user"

                >
                  Use Analysis Credits
                </Link>
              </div>
            </div>
            <Row>
              <Col xl={12} lg={12} md={12} sm={16} xs={24}>


                <Title level={5}>Analysis Order Title</Title>
                <Input placeholder="Enter order title here" onKeyUp={(e) => {
                  setTitle(e.target.value)
                }} />
              </Col>
              <Col className="gutter-row" xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title style={{ textAlign: 'left' }} level={4}>Upload Your COA PDF Files</Title>
                <Upload uploadedFile={uploadedFile} credit={100} availCredit={availcredit} accept={'.pdf'} />
                <div className="start-order-list">
                  <List
                    bordered
                    footer={
                      <div className="list-footer">
                        <Button
                          className="my-button"
                          style={{
                            backgroundColor: "#609BE5",
                            color: "#fff",
                            width: "initial",
                            display: "inline-block",
                          }}
                          loading={loading}
                          htmlType="submit"
                          onClick={userOrder}
                        >
                          Start Order
                        </Button>
                        {message ? <Alert type='error' message={message} /> : ''}
                        {/* <Text>
                      Upon starting an order you will receive a digital invoice
                      via e-mail that must be paid before the order is
                      processed.
                    </Text> */}
                      </div>
                    }
                  >
                    <List.Item>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text>{packages?.package_name}</Text>
                        <Text>COAs Analyzed: {items.length}</Text>
                      </div>
                      <Typography>${packages?.total_original_amount ? packages?.total_original_amount : 0}</Typography>
                    </List.Item>
                    {/* <List.Item>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text>Volume Discount: {packages?.discount_percentage ? packages?.discount_percentage : 0}%</Text>
                      </div>
                      <Typography>- ${packages?.discount_price ? packages?.discount_price : 0}</Typography>
                    </List.Item> */}
                    <List.Item>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text>Order Total:</Text>
                      </div>
                      <Typography> ${packages?.order_total ? packages?.order_total : 0}</Typography>
                    </List.Item>
                  </List>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12} sm={16} xs={12} >
            <Row >
              {/* <Col xl={24} lg={24} md={24} sm={16} xs={24}>
                <Card title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>Analysis Discounts</Title>
                  </div>
                }
                >
                  <ul className="bulk-discount-list">
                    <li>10% Discount at {discount?.twenty_pdfs_discount} COAs</li>
                    <li>15% Discount at {discount?.fifty_pdfs_discount} COAs</li>
                    <li>20% Discount at {discount?.one_fifty_pdfs_discount} COAs</li>
                    <li>25% Discount at {discount?.three_hundred_pdfs_discount} COAs</li>
                  </ul>
                </Card>
              </Col> */}
              <Col className="gutter-row" xl={24} lg={24} md={24} sm={24} xs={24}>
                {errorFile.length > 0 && <PdfFileLists showButton={false} showtag={false} count={count} title={'Rejected Files'} items={errorFile} onRemove={removeItem} />}
                <PdfFileLists showButton={true} showtag={false} count={count} items={items} onRemove={removeItem} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default StartOrder;
