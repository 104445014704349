// Libraries
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";

// Components
import CreateNewUser from "./pages/admin/createnewuser/CreateNewUser";
import Overview from "./pages/admin/overview/Overview";
import Login from "./pages/auth/Login";
import DeliverOrder from "./pages/admin/deliverorder/DeliverOrder";
import MainPage from "./pages/admin/mainpage/MainPage";
import UserManagement from "./pages/admin/usermanagement/UserManagement";
import MyAccount from "./pages/user/myaccount/MyAccount";
import ViewOrders from "./pages/user/vieworders/ViewOrders";
import SubscriptionUser from "./pages/user/subscriptionuser/SubscriptionUser";
import StartOrder from "./pages/user/startorder/StartOrder";
import OrderSubmitted from "./pages/user/ordersubmitted/OrderSubmitted";
import ViewCsvVisual from "./pages/user/viewcsvvisual/ViewCsvVisual";
import NewAnalysisOrder from "./pages/user/newanalysisorder/NewAnalysisOrder";
import PrivateRoutes from "./routes/PrivateRoutes";
import SubUserRoutes from "./routes/SubUserRoutes";
import UserRoute from "./routes/UserRoutes";
import PublicRoute from "./routes/PublicRoute";
import AnalysisDepthTier from "./pages/user/analysisdepthtier/AnalysisDepthTier";
import MonthlyAnalysisCredits from "./pages/user/monthlyanalysiscredits/MonthlyAnalysisCredits";

// Css
import "./App.css";
import useToken from "./hooks/useToken";
import { useSelector } from "react-redux";
import ShareRoute from "./routes/shareRoute";
import CheckOut from "./pages/user/check-out";
import Profile from "./pages/admin/profile/Profile";
import ResetPassword from "./pages/reset-password/resetPassword";
import ForgotPassword from "./pages/forgot-password/forgottPassword";
import TermCondition from "./pages/termsCondition/termCondition";
import StripeCheckOut from "./pages/user/stripe-card";
import { CheckPaymentRoute } from "./routes";
import SignUp from "./pages/SignUp/signUp";
import CreateOrder from "./pages/admin/createOrder/CreateOrder";

function App() {
  const { token, isAdmin, accountType } = useToken();

  useEffect(() => { }, [token, isAdmin, accountType])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route exact path="/login" element={<Login />}></Route>
            {/* <Route exact path="/register" element={<SignUp />}></Route> */}
            <Route exact path="/set-password" element={<ResetPassword />}></Route>
            <Route exact path="/reset-password" element={<ResetPassword />}></Route>
            <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
            {/* <Route exact path="/tos" element={<TermCondition />}></Route> */}
          </Route>
          {/* {
            isAdmin ? */}
          <Route path="/" element={<PrivateRoutes />}>
            <Route exact path="/profile" element={<Profile />}></Route>
            <Route exact path="/strip-check-out/:clientSecret/:id/:packages/:price" element={<StripeCheckOut />}></Route>
            <Route path="/" element={<CheckPaymentRoute />}>
              <Route path="/" element={<SubUserRoutes />}>
                <Route exact path="/" element={<Navigate replace to="/main-page" />}></Route>
                <Route exact path="/main-page" element={<MainPage />}></Route>
                <Route exact path="/create-new-user" element={<CreateNewUser />}></Route>
                <Route exact path="/edit-user/:id" element={<CreateNewUser />}></Route>
                <Route exact path="/user-management" element={<UserManagement />} ></Route>
                <Route exact path="/my-account" element={<MyAccount />}></Route>
                <Route exact path="/deliver-order/:id" element={<DeliverOrder />}></Route>
                <Route exact path="/create-order" element={<CreateOrder />}></Route>
                {/* <Route exact path="/profile" element={<Profile />}></Route> */}
              </Route>
              {/* : */}
              <Route path="/" element={<UserRoute />}>
                <Route path="/"
                  element={<Navigate replace to="/new-analysis-order" />}></Route>
                <Route element={<ShareRoute />}>
                  <Route exact path="/new-analysis-order"
                    element={<StartOrder />} ></Route>
                  <Route exact path="/new-analysis-order/:packageId/:price" element={<NewAnalysisOrder />} ></Route>
                </Route>
                {/* <Route exact path="/start-order/:id" element={<StartOrder />}></Route> */}
                {/* <Route exact path="/start-order" element={<StartOrder />}></Route> */}
                {/* <Route exact path="/subscription-user/:id" element={<SubscriptionUser />}></Route> */}
                <Route exact path="/subscription-user" element={<SubscriptionUser />}></Route>
                <Route exact path="/view-orders" element={<ViewOrders />}></Route>
                <Route exact path="/order-submitted/:id" element={<OrderSubmitted />}></Route>
                <Route exact path="/view-csv/:id" element={<ViewCsvVisual />}></Route>
                <Route exact path="/analysis-depth-tier" element={<AnalysisDepthTier />}></Route>
                <Route exact path="/check-out/:clientSecret/:id/:packages/:price/:amount" element={<CheckOut />}></Route>
                <Route element={<PublicRoute />}>
                  {/* <Route exact path="/monthly-analysis-credits" element={<MonthlyAnalysisCredits />} ></Route> */}
                  {/* <Route exact path="/overview" element={<Overview />}></Route> */}
                </Route>
              </Route>
            </Route>
          </Route>

          {/* } */}
        </Routes >
      </BrowserRouter >
    </div >
  );
}

export default App;
