


import { useStripe, useElements, PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'


import './style.css'
import { useSelector } from 'react-redux';
import { Typography, Card, Col, Row } from 'antd';
import stripLogo from "../../../assets/images/stripe.png";
import stripeSealed from "../../../assets/images/stripeSealed.webp";
const { Title, Text } = Typography;


const packageValue = [
    { id: 0, name: 'Adult Use Basic' },
    { id: 1, name: 'Therapeutic Basic' },
    { id: 2, name: 'Therapeutic & Adult Use Basic' },
    { id: 3, name: 'Therapeutic & Adult Use Advanced' },
    { id: 4, name: 'Therapeutic & Adult Use Complete' },
    { id: 6, name: '6 Adult Use' },
    { id: 7, name: '6 Therapeutic' },
    { id: 8, name: 'Combined Analysis' }

]
const CheckoutForm = ({ id, packages, price, amount }) => {
    debugger
    console.log(packageValue[parseInt(packages) > 5 ? parseInt(packages) - 1 : parseInt(packages)]?.name)
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate()
    const [processing, setProcessing] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const userEmail = useSelector((state) => state.userReducer.user.email)

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setErrorMessage("Payment succeeded!");
                    break;
                case "processing":
                    setErrorMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setErrorMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setErrorMessage("Something went wrong.");
                    break;
            }
        });
        stripe.confirmCardPayment(clientSecret).then(({ paymentIntent }) => {
        })
    }, [stripe]);

    const handleSubmit = async (event) => {
        setProcessing(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({

            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: (packages != "null" && price != "null") ? `${window.location.origin}/new-analysis-order/${packages}/${price}` : `${window.location.origin}/order-submitted/${id}`,
                // return_url:  `${window.location.origin}//${packages}/${price}`,
            },
        })
        // .then(({ paymentIntent }) => {
        //     debugger
        // });
        setProcessing(false)
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setErrorMessage(result.error.message);
        } else {
            console.log(result)
            setErrorMessage(null);

            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <>

            <div style={{ textAlign: 'center' }}>
                <img
                    src={stripLogo}
                    alt="Stripe Seal"
                    style={{ width: '150px', marginBottom: '20px' }}
                />
            </div>
            <Row gutter={24} >

                {packages != "null" && price != "null" &&
                    <Col className="gutter-row" xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Card
                        >
                            <div >
                                <Title level={5}>Package : {packageValue[parseInt(packages) > 5 ? parseInt(packages) - 1 : parseInt(packages)]?.name} {price} Credits</Title>
                                <Title level={5}>Total payment : ${amount}</Title>
                            </div>
                        </Card>
                    </Col>
                }
            </Row >
            <div className="" style={{ marginTop: "10px" }}>
                <div className='px-4'>

                    <form id="payment-form" onSubmit={handleSubmit}>




                        {packages == "null" && price == "null" && <LinkAuthenticationElement
                            options={{
                                defaultValues: {
                                    email: userEmail,
                                },
                            }} />
                        }


                        <PaymentElement />
                        <button className="my-button"
                            style={{
                                backgroundColor: "#609BE5",
                                color: "#fff",
                                width: "initial",
                                display: "inline-block",
                                marginTop: "15px",
                                cursor: "pointer"

                            }}
                            disabled={!stripe} id="submit">
                            <span > {processing ? 'Processing ...' : 'Pay Now'}</span>
                        </button>
                        <img
                            src={stripeSealed}
                            className='stripSeal'
                            alt="Stripe Seal"
                        />
                        {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
                    </form>
                </div>
            </div >
        </>
    )
};

export default CheckoutForm;